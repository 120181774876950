import { template as template_3f6d65756b1f4c15b7aa294665592855 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3f6d65756b1f4c15b7aa294665592855(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
