import { template as template_a3fdcf7f89714c1eb38189cef51d4462 } from "@ember/template-compiler";
const FKLabel = template_a3fdcf7f89714c1eb38189cef51d4462(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
