import { template as template_c1e1361d5c794a9e9a1c586448db408c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_c1e1361d5c794a9e9a1c586448db408c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
